<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <section class="bean bg-background-light px-4 md:px-6">
    <div class="mx-auto max-w-6xl py-8 antialiased md:py-12">
      <h2
        class="text-center text-2xl font-medium text-supporting-black-87 md:text-3xl"
      >
        {{ block.heading }}
      </h2>
      <p class="mt-3 text-center text-lg font-medium text-supporting-black-87">
        {{ block.textArea }}
      </p>
      <div
        class="mx-auto mt-8 grid max-w-6xl auto-cols-fr gap-2 md:grid-cols-2 lg:gap-48"
      >
        <div class="mt-6 flex flex-col gap-8 md:gap-12">
          <template v-for="item in block.children" :key="item.id">
            <div
              v-if="item.typeHandle === 'BlockSingleText'"
              class="hidden break-words text-md text-supporting-black-87 md:block [&_strong]:mb-3 [&_strong]:block [&_strong]:text-lg"
              v-html="item.redactorSimple"
            ></div>
          </template>

          <div class="md:hidden">
            <Swiper class="-m-4" :show-arrows="false">
              <template #items>
                <template v-for="item in block.children" :key="item.id">
                  <div
                    v-if="item.typeHandle === 'BlockSingleText'"
                    class="w-full shrink-0 snap-start overflow-hidden rounded-xl px-2 md:w-1/2"
                  >
                    <div
                      class="max-w-full whitespace-normal break-words px-2 text-md text-supporting-black-87 [&_strong]:mb-3 [&_strong]:block [&_strong]:text-lg"
                      v-html="item.redactorSimple"
                    ></div>
                  </div>
                </template>
              </template>
            </Swiper>
          </div>

          <template v-for="item in block.children" :key="item.id">
            <div v-if="item.typeHandle === 'BlockSingleButton'">
              <BlockSingleButton :block="item" />
            </div>
          </template>
        </div>
        <div
          class="md:pl-22 order-first flex justify-center md:order-none md:pl-5 md:pt-5"
        >
          <div ref="lazyVideoWrapper" class="video-wrapper">
            <div class="overflow-hidden rounded-[30px]">
              <Videos
                v-if="videoWrapperVisible"
                class="rounded-[30px]"
                :videos="videos"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import h265 from '../assets/videos/vet-call-journey/vet-call-journey_h265.mp4';
import av1 from '../assets/videos/vet-call-journey/vet-call-journey_av1.mp4';
import webm from '../assets/videos/vet-call-journey/vet-call-journey.webm';
import theora from '../assets/videos/vet-call-journey/vet-call-journey.ogv';
import webp from '../assets/videos/vet-call-journey/vet-call-journey.webp';
import h264 from '../assets/videos/vet-call-journey/vet-call-journey_h264.mp4';

defineProps({
  block: {
    type: Object,
    default: () => {},
  },
  globals: {
    type: Object,
    default: () => {},
  },
});

const videos = ref([
  {
    id: 'vet-call-journey',
    type: 'inline',
    files: {
      h265,
      av1,
      webm,
      h264,
      theora,
      webp,
    },
  },
]);
const lazyVideoWrapper = ref(null);

const videoWrapperVisible = ref(false);

onMounted(() => {
  if ('IntersectionObserver' in window) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            videoWrapperVisible.value = true;
          }
        });
      },
      {
        threshold: 0.1,
        rootMargin: '400px 0px 400px 0px',
      }
    );
    if (lazyVideoWrapper.value) {
      observer.observe(lazyVideoWrapper.value);
    }
  }
});
</script>

<style scoped>
.video-wrapper {
  background-repeat: no-repeat;
  padding: 4px 6px 0px 6px;
  width: 215px;
  height: 438px;
  background-size: contain;
  background-image: url('@brand/assets/images/phone-background.svg');
}

@media screen and (max-width: 768px) {
  .video-wrapper {
    background-repeat: no-repeat;
    padding: 4px 6px 4px 6px;
    width: 180px;
    height: 369px;
    background-size: contain;
    background-image: url('@brand/assets/images/phone-background-mobile.svg');
  }
}

.bean {
  background-repeat: no-repeat;
  background-size: 620px auto;
  background-position: calc(100vw / 2 + 30px) 100%;
  background-image: url('@brand/assets/images/bean-v1.svg');
}

@media screen and (max-width: 768px) {
  .bean {
    background-repeat: no-repeat;
    background-size: 660px 375px;
    background-position: center 45.5%;
    background-image: url('@brand/assets/images/bean-v2.svg');
  }
}
</style>
